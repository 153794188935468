import './related-content-teaser-list.scss';

// import { Slider } from './../../components/slider/slider';
import { A11y, Navigation } from 'swiper';

class RelatedContent {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-relatedcontent'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$relatedContent = $element;
        this.$relatedContentSlider = this.$relatedContent.querySelector('[' + this.settings.initAttr + '="slider"]');
        this.$relatedContentSlides = this.$relatedContentSlider.querySelectorAll('[' + this.settings.initAttr + '="slide"]');
        this.relatedContentSlider = null;

        this.resizeHandler = window.resizeHandler;
        this.sliderActive = false;

        if (this.$relatedContentSlides.length > 1) {
            import(
                /* webpackChunkName: "slider/slider.js" */
                './../../components/slider/slider').then(({ Slider }) => {
                this.Slider = Slider;
                this.initialize();
            });
        }
        // this.initialize();
    }

    initialize () {
        this.checkSize();
        this.resizeHandler.customFunctions.push(() => {
            this.checkSize();
        });
    }

    checkSize () {
        if (window.innerWidth < 768 && this.$relatedContentSlides.length > 1) {
            if (this.sliderActive === false) {
                this.initSlider();
            }
        } else if (window.innerWidth < 1440 && this.$relatedContentSlides.length > 2) {
            if (this.sliderActive === false) {
                this.initSlider();
            }
        } else if (window.innerWidth >= 1440 && this.$relatedContentSlides.length > 3) {
            if (this.sliderActive === false) {
                this.initSlider();
            }
        } else {
            if (this.sliderActive === true) {
                this.destroySlider();
                this.sliderActive = false;
            }
        }
    }

    initSlider () {
        this.relatedContentSlider = new this.Slider(this.$relatedContentSlider, {
            modules: [A11y, Navigation],
            speed: 500,
            slidesPerView: 'auto',
            slidesPerGroup: 1,
            centeredSlides: false,
            centeredSlidesBounds: true,
            initAttr: this.settings.initAttr,
            centerInsufficientSlides: true,
            breakpoints: {
                320: {
                    centeredSlides: true
                },
                768: {
                    slidesPerGroup: 2
                },
                1440: {
                    slidesPerGroup: 3
                }
            }

        });

        this.sliderActive = true;
    }

    destroySlider () {
        if (typeof this.relatedContentSlider !== 'undefined' && this.relatedContentSlider !== null) {
            this.relatedContentSlider.destroy();
        }
    }
}

export { RelatedContent };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$relatedContent = $context.querySelectorAll('[data-relatedcontent="root"]');
        for (let i = 0; i < $$relatedContent.length; i++) {
            const $relatedContent = $$relatedContent[i];

            const relatedContentAPI = new RelatedContent($relatedContent);
            $relatedContent.API = relatedContentAPI;
        }
    }
});
